import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Apollo = () => (
  <Layout>
    <Seo title="Page two" />
    <div
      className="main-container"
      style={{ height: `51vh`, padding: `2em 5em` }}
    >
      <h1>Apollo</h1>
    </div>
  </Layout>
)

export default Apollo
